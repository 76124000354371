import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'

//==== Load Slick Slider Component =========
import '../src/core/slick/slick.js';
import '../src/core/fontawesome-free-6.7.1-web/css/all.css';
import '../src/core/fontawesome.css';

import './assets/css/animate.css';
import './assets/css/swiper.min.css';
import './assets/css/magnific-popup.css';
import './assets/css/recoleta-font.css';
import './assets/css/main.css';
import '../src/App.css'
import './assets/css/events.css'
import loadeImg from '../src/assets/preloader.png';

import Markup from './markup/markup';

function App() {
  return (
    <div className="App">
      <div className="xb-backtotop">
        <a href="#" className="scroll">
            <i className="far fa-arrow-up"></i>
        </a>
      </div>
      <div id="preloader">
        <div id="loader" className="loader">
          <div className="loader-container">
            <div className="loader-icon">
              <img src={loadeImg} alt="Loader" />
            </div>
          </div>
        </div>
      </div>
      <Markup />
    </div>
  );
}

export default App;
