import React, { useState, useEffect } from 'react';
import MenuItems from './MenuItems';
import getBaseUrl from '../../base/htttp';

const MonbileMenu = ({ headedata }) => {
	const [data, setData] = useState([]);
	const getData = () => {
		fetch(getBaseUrl() + "menu", { method: 'POST' })
			.then((response) => response.json())
			.then((result) => {
				setData(result.data);
			});
	}

	useEffect(() => {
		getData();
	}, []);

	return (
		<>
		<div className="xb-menu-close xb-hide-xl xb-close"></div>
		<div className="xb-logo-mobile xb-hide-xl">
			<a href="/" rel="home"><img src={headedata.logo_url} alt="caretransport_logo" /></a>
		</div>
		<nav className="xb-header-nav">
			<ul className='xb-menu-primary clearfix'>
				{data.map((menu, index) => {
					return <MenuItems is_mob_menu="true" items={menu} key={index} />;
				})}
			</ul>
		</nav>
		</>
	);
}

export default MonbileMenu;