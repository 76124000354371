import Dropdown from './Dropdown';
const MenuItems = ({ items , is_mob_menu }) => {
    return (
      <li className={items.has_child===1 ?"menu-item menu-item-has-children":"menu-item"}>
        {items.has_child===1 ? (
          <>
                <a href={items.url} target={items.target}>
                    <span>{items.title}</span>
                </a>
                <Dropdown is_mob_menu={is_mob_menu} submenus={items.childreen} />                            
          </>
        ) : (          
          <a href={items.url} target={items.target}><span>{items.title}</span></a>
        )}
      </li>
    );
  };
  
  export default MenuItems;